import { Link } from "gatsby";
import React from "react";
import * as s from "./author.module.css";

const AuthorBio = ({ author }) => {
  return (
    <>
      <div className={`${s.authorWrapper}`}>
        <img src={author.avatar.url} className={`mr-3 ${s.authorImg}`} alt={author.name} />
        <Link to={author.uri} className={`${s.authorName}`}>
          {author.name}
        </Link>
        <span className={`${s.authorDescription}`}> {author.seo?.social?.wikipedia}</span>
        {author?.posts?.nodes?.length > 0 && <div className={s.articlesCount}>{author?.posts?.nodes?.length} articles </div>}
      </div>
    </>
  );
};

export default AuthorBio;
