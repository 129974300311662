// extracted by mini-css-extract-plugin
export var about = "blog-author-module--about--cae6f";
export var authorImg = "blog-author-module--author-img--6f993";
export var authorSocials = "blog-author-module--author-socials--e2f17";
export var heading = "blog-author-module--heading--c8f8f";
export var headingBg = "blog-author-module--heading-bg--866c6";
export var headingInner = "blog-author-module--heading-inner--a95b5";
export var postFooter = "blog-author-module--post-footer--c09c4";
export var postLine = "blog-author-module--post-line--1d9ef";
export var shareBlock = "blog-author-module--share-block--3b932";
export var socials = "blog-author-module--socials--d56d8";