import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Slider from "react-slick";
import PostAlter from "../post-alter/post-alter";
import Post from "../post/post";
import SlickArrowLeft from "./../SlickArrowLeft";
import SlickArrowRight from "./../slickArrowRight";
import * as s from "./news.module.css";
import { Link } from "gatsby";

const News = ({ title, items, useAlterPost, hideSlider }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: [date], order: DESC }, limit: 3, skip: 0) {
        nodes {
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          featuredImage {
            node {
              gatsbyImage(width: 600,quality: 80) 
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData( quality: 80 )
                }
              }
            }
          }
        }
      }
    }
  `);
  let posts = [];
  if (!items?.nodes?.length) {
    posts = data.allWpPost.nodes;
  } else {
    posts = items.nodes;
  }

  const defaultTitle = <>Our Latest <span style={{ backgroundImage: 'linear-gradient(180deg, #FC6905 0%, #EC5D05 100%)', }}>Blog</span> Posts</>;

  if (!posts.length) {
    return <></>;
  }

  const newsSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: false,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <section className={s.blogBlock}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className={`gradient-title ${s.blogTitle}`}>
                {title !== undefined ? title : defaultTitle}
              </h2>
            </div>
          </div>
          {!!hideSlider &&
            <div className="row justify-content-center">
              {posts.map(post => {
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={post.uri}>
                    {!useAlterPost && <Post post={post} />}
                    {!!useAlterPost && <PostAlter post={post} />}
                  </div>
                );
              })}
            </div>
          }
          {!hideSlider &&
            <Slider {...newsSettings}>
              {posts.map(post => {
                return (
                  <div className="col-12" key={post.uri}>
                    {!useAlterPost && <Post post={post} />}
                    {!!useAlterPost && <PostAlter post={post} />}
                  </div>
                );
              })}
            </Slider>
          }
          <div className="d-flex justify-content-center">
            <Link to='/blog' className={s.blogLink}>
              <span>Read More</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
