import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./post-alter.module.css";

const PostAlter = ({ post, title, excerpt, featuredImage, date, url }) => {
  const image = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.altText || ``,
  };
  return (
    <>
      <article className={s.postItem}>
        {image?.data && (
          <GatsbyImage
            loading="eager"
            placeholder="none"
            image={image.data}
            alt={image.alt}
            className={s.postImg}
          />
        )}
        <Link to={post.uri} className={s.postTitle}>
          <h3>{parse(post.title)}</h3>
        </Link>
      </article>
    </>
  );
};

export default PostAlter;
